<template>
  <div class="content">
    <PageHeader :title="$t('sms.usage-report.header')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-lg-12 col-xl-12">
          <div class="card">
            <div class="card-header">
              <form class="form-inline">
                <date-range-picker
                  class="form-control"
                  :format="'YYYY-MM-DD'"
                  :options="options"
                  @change="changeDate"
                />
              </form>
            </div>
            <div class="card-body block-el p-0">
              <div class="table-responsive">
                <div
                  v-if="!usage.products && fetched"
                  class="text-center table-placeholder"
                >
                  <br />
                  <i class="fas fa-tachometer-alt font-size-80"></i>
                  <h5 class="card-title m-t-20">Nenhum gasto encontrado</h5>
                </div>
                <table v-if="fetched" class="table">
                  <thead class="bg-light">
                    <tr>
                      <th>{{$tc('generic-str.lbl-product', 1)}}</th>
                      <th>{{$t('generic-str.lbl-quantity')}}</th>
                      <th>{{$t('generic-str.lbl-cost')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="font-weight-500">Total</td>
                      <td class="font-weight-500">{{ usage.totalCount }}</td>
                      <td class="font-weight-500">
                        {{ usage.totalPrice | currency4D }}
                      </td>
                    </tr>
                    <tr v-for="product in usage.products" :key="product.name">
                      <td>
                        {{ product.description }}
                      </td>
                      <td>{{ product.count }}</td>
                      <td>{{ product.price | currency4D }}</td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="qt-block-ui" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import PageHeader from '@/components/PageHeader.vue';
import UsageService from '@/services/usage.service';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
  },
  data() {
    return {
      fetched: true,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        startDate: moment().format('01/MM/YYYY'),
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      usage: [],
      form: {
        category: 'sms',
        date: {
          gte: moment().format('YYYY-MM-01'),
          lte: moment().format('YYYY-MM-DD'),
        },
      },
    };
  },
  methods: {
    changeDate(date) {
      if (date) {
        this.form.date.gte = date[0];
        this.form.date.lte = date[1];
      }
      this.fetchUsage();
    },
    fetchUsage() {
      this.fetched = false;
      UsageService.getAllUsage(this.form).then((response) => {
        this.usage = response;
        this.fetched = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.table-responsive {
  min-height: 150px;
}
</style>
